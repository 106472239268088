import React, { Component } from 'react'
import { Link, withRouter } from 'react-router-dom'
import queryString from 'query-string'

import AuthForm from '../../components/AuthForm'
import ResetPasswordForm from '../../components/ResetPasswordForm'
import AppLayout from '../../components/AppLayout'

class ResetPassword extends Component {
  render () {
    const { location } = this.props
    const query = queryString.parse(location.search)

    return (
      <AppLayout>
        <AuthForm
          heading='Reset Your JustGiving Password'
          formComponent={ResetPasswordForm}
          footer={
            <Link to={{
              pathname: '/sign_up',
              search: queryString.stringify(query)
            }}
            >Need an account?
            </Link>
          }
        />
      </AppLayout>
    )
  }
}

export default withRouter(ResetPassword)
