import React, { Component } from 'react'
import { Link, withRouter } from 'react-router-dom'
import queryString from 'query-string'

import AuthForm from '../../components/AuthForm'
import SignupForm from '../../components/SignupForm'
import AppLayout from '../../components/AppLayout'

class Signup extends Component {
  render () {
    const { location } = this.props
    const query = queryString.parse(location.search)

    return (
      <AppLayout>
        <AuthForm
          heading='Parent/Guardian Registration'
          formComponent={SignupForm}
          footer={
            <Link to={{
              pathname: '/sign_in',
              search: queryString.stringify(query)
            }}
            >Already have an account?
            </Link>
          }
        />
      </AppLayout>
    )
  }
}

export default withRouter(Signup)
