import React, { Component } from 'react'
import { Link, withRouter } from 'react-router-dom'
import queryString from 'query-string'

import AuthForm from '../../components/AuthForm'
import LoginForm from '../../components/LoginForm'
import AppLayout from '../../components/AppLayout'

class Login extends Component {
  render () {
    const { location } = this.props
    const query = queryString.parse(location.search)

    return (
      <AppLayout>
        <AuthForm
          heading='Parent/Guardian Login'
          formComponent={LoginForm}
          footer={
            <span>
              <Link to={{
                pathname: '/sign_up',
                search: queryString.stringify(query)
              }}
              >Need an account?
              </Link>{' '}|{' '}
              <Link to={{
                pathname: '/sign_in/reset_password',
                search: queryString.stringify(query)
              }}
              >Forgot your password?
              </Link>
            </span>
          }
        />
      </AppLayout>
    )
  }
}

export default withRouter(Login)
