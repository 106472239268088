import React from 'react'
import axios from 'axios'
import DonationFeed from '../../../../../common/components/DonationFeed'

class Donations extends React.Component {
  constructor (props) {
    super()
    this.state = {
      data: [],
      status: 'fetching'
    }
    this.apiClient = axios.create({
      baseURL: props.baseUrl
    })
  }

  componentDidMount () {
    const { page } = this.props

    this.apiClient.get(`/justgiving/api/v1/fundraising/pages/${page.slug}/donations`)
      .then(({ data }) => data)
      .then(data => this.setState({ status: 'fetched', data }))
      .catch(error => {
        this.setState({ status: 'failed' })
        return Promise.reject(error)
      })
  }

  render () {
    const { data, status } = this.state
    const { application, child } = this.props

    return (
      <DonationFeed
        manualDonations={[
          child.bonus_amount_in_cents > 0 && {
            isBonus: true,
            image: application.logo_avatar_url,
            name: 'School-Fun-Run',
            message: application.bonus_amount_message,
            amount: child.bonus_amount_in_cents / 100
          },
          child.adjustment_amount_in_cents > 0 && {
            image: application.logo_avatar_url,
            name: 'Admin',
            message: 'Online amount adjustment',
            amount: child.adjustment_amount_in_cents / 100
          }
        ].filter(Boolean)}
        child={child}
        data={data}
        hideSurnames
        status={status}
      />
    )
  }
}

export default Donations
