import React, { Component } from 'react'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { clearFlash } from '../../store/flash'
import { withRouter } from 'react-router-dom'
import withStyles from 'constructicon/with-styles'
import { parse } from 'query-string'
import styles from './styles'

import Flash from '../../../../../common/components/Flash'
import Section from '../../../../../common/components/Section'
import Tabs from '../Tabs'
import DefaultWrapper from '../DefaultWrapper'
import WristbandWrapper from '../WristbandWrapper'
class AppLayout extends Component {
  render () {
    const { children, flash = {}, clearFlash, styles, location } = this.props
    const { landing } = parse(location.search)
    const Wrapper = landing === 'wristband' ? WristbandWrapper : DefaultWrapper

    return (
      <Wrapper>
        <Section styles={styles.section} header={<Tabs />}>
          <div>{children}</div>
        </Section>
        <Flash {...flash} clearFlash={clearFlash} />
      </Wrapper>
    )
  }
}

const mapState = ({ flash }) => ({ flash })
const mapActions = { clearFlash }

export default compose(
  withStyles(styles),
  withRouter,
  connect(mapState, mapActions)
)(AppLayout)
